import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { RootStore } from "../../../../core/stores/RootStore";
import { StoreService } from "../../../../core/service/StoreService";
import { IDropdownOption } from "office-ui-fabric-react";
import { UserService } from "../../../../core/service/UserService";
import Cookies from "universal-cookie/es6/Cookies";
import { RoutesObj } from "../../../../core/contstants/Routes";
import { GridReadyEvent } from "@ag-grid-enterprise/all-modules";

import { IErrorModel } from "../../../../core/models/IErrorModel";
import { OfferService } from "../../../../core/service/OfferService";

export class CustomerStore {
  public StoreService: StoreService = null;
  public UserService: UserService = null;
  public OfferService: OfferService = null;
  @observable showDialog: boolean = false;
  public adminPass: any;
  @observable offers: Array<any>;
  @observable columnDef: Array<any>;
  RootStore: RootStore = null;
  @observable errorMessage: any = null;
  @observable sqtData: any = null;
  @observable closedPanels: Array<string> = [];
  @observable errors: Array<IErrorModel> = [];
  @observable isSubmitted: boolean = false;
  @observable intrest: boolean = false;
  @observable statement1: boolean = false;
  @observable statement2: boolean = false;
  @observable statement3: boolean = false;
  @observable statement4: boolean = false;
  @observable done: boolean = false;
  @observable reject: boolean = false;

  @observable showDeclinePopup: boolean = false;
  @observable showDeclineConfirmPopup: boolean = false;


  constructor(rootStore: RootStore, type: string, categoryId: number, moduleId: number) {
    this.StoreService = new StoreService();
    this.UserService = new UserService();
    this.OfferService = new OfferService();
    this.RootStore = rootStore;
  }

  public getFullAddress(isAllando: boolean) {
    const addressParts = ['Iranyitoszam', 'Varos', 'KozteruletNeve', 'KozteruletJellege', 'Hazszam'];

    let address = '';
    addressParts.forEach(element => {
      const property = (isAllando ? 'Allando_' : 'Levelezesi_') + element;
      if (this.sqtData[property]) {
        address += this.sqtData[property] + " ";
      }
    });

    return address.trim();
  }
  cancelIntrest() {
    this.statement1 = false;
    this.statement1 = false;
    this.statement1 = false;
    this.statement1 = false;
    this.intrest = false;
  }
  isMobile() {
    // let routeParts = this.router.url.split('/');
    // let routeUrl = routeParts[1] + '/' + routeParts[2];
    // let routerConfig = _.find(this.router.config, (conf: any) => { return conf.path == routeUrl });
    // let isMobile = routerConfig != null ? routerConfig.data.isMobileCompatible : false;
    //
    return (document && document.body && document.body.offsetWidth < 900);
  }
  Init = flow(function* (this: CustomerStore) {
    if (!this.RootStore.SelectedStore) {
      // this.RootStore.RouterStore.gotToRoute(RoutesObj.Login.id, {}, null, false);
    } else {
      let name = "isAdmin";
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
      if (results && results.length > 1) {
        if (results[2] == 'true') {
          this.showDialog = true;
        }
      }


    }

    //this.RootStore.IsLoading = false;
  });
  getOfferById = flow(function* (this: CustomerStore, id: string) {
    this.RootStore.addloading('getOfferById')

    this.isSubmitted = false;

    try {
      let result = yield this.OfferService.getOfferById(id);
      if (result) {
        if (result.Termekek && result.Termekek.length > 0) {
          for (let index = 0; index < result.Termekek.length; index++) {
            const element = result.Termekek[index];
            if (result.Termekek[index].VasarlasDatuma) {
              result.Termekek[index].VasarlasDatuma = new Date(result.Termekek[index].VasarlasDatuma);
            }

          }

        }
        this.sqtData = result;
        // if (this.sqtData.Adoszam) {
        //   this.showMaganSzemelyDialog = true;
      }
      // }
    }
    catch (ex) {
      console.log(ex)
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('saveOffer')
  });

  SaveofferDecline = flow(function* (this: CustomerStore) {

    this.RootStore.addloading('SaveofferDecline')

    this.isSubmitted = false;

    try {
      let result = yield this.OfferService.SaveofferDecline(this.sqtData);
      this.showDeclineConfirmPopup = false;
      this.reject = true;
      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      console.log(ex)
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('SaveofferDecline')

  });

  SaveofferAccept = flow(function* (this: CustomerStore) {

    if (this.errors.length) {
      this.isSubmitted = true;
      return;
    }
    if (!this.statement1 || !this.statement2 || !this.statement3 || !this.statement4) {
      alert("Minden nyilatkozat elfogadása kötelező!");
      return;
    }
    this.RootStore.addloading('SaveofferAccept')

    try {
      let result = yield this.OfferService.SaveofferAccept(this.sqtData);
      this.done = true;

      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      console.log(ex)
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('SaveofferAccept')
  });
  intrestOffer() {
    // window.scrollTo({ bottom: 0, behavior: "smooth" });
    this.intrest = true;
    setTimeout(function () {
      window.scrollTo(0, document.body.scrollHeight);
    }, 500);

  }
  @action getErrorMessage = (property: string, label: string, object?: any) => {

    const index = this.errors.findIndex(p => p.key == property);
    let message = `Hiányzó: ${label}`;

    switch (property) {
      case 'Email':
        if (!object[property]) {
          if (index == -1) {
            this.errors.push({ key: property, value: message });
          }

          return this.isSubmitted ? message : null;
        } else {
          if (!object[property]
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            message = `${label} formátuma nem megfelelő!`;
            if (index == -1) {
              this.errors.push({ key: property, value: message });
            } else {
              this.errors[index].value = message;
            }

            return this.isSubmitted ? message : null;
          } else {
            if (index > -1) {
              let errors = [...this.errors];
              errors.splice(index, 1);

              this.errors = errors;

              return null;
            }
          }
        }
        break;
      default:
        if (!object[property]) {
          if (index == -1) {
            this.errors.push({ key: property, value: message });
          }

          return this.isSubmitted ? message : null;
        } else {
          if (index > -1) {
            let errors = [...this.errors];
            errors.splice(index, 1);

            this.errors = errors;

            return null;
          }
        }
        break;
    }
  }

  @action onClosePanelClicked = (panelId: string) => {
    const index = this.closedPanels.indexOf(panelId);
    if (index == -1) {
      this.closedPanels.push(panelId);
    } else {
      const closedPanels = [...this.closedPanels];
      closedPanels.splice(index, 1);
      this.closedPanels = closedPanels;
    }
  }

  @action onValueChanged = (property: string, value: any, object?: any) => {
    if (object) {
      object[property] = value;
    } else {
      this[property] = value;
    }
  }

  @action onSelectChanged = (property: string, isChecked: boolean, object?: any) => {
    if (object) {
      object[property] = isChecked;
    } else {
      this[property] = isChecked;
    }
  }

  @action onCheckBoxSelectChanged = (property: string, code: string, isChecked: boolean) => {
    const index = this[property].indexOf(code);

    if (isChecked) {
      if (index == -1) {
        this[property].push(code);
      }
    } else {
      if (index > -1) {
        this[property].splice(index, 1);
      }
    }
  }

  @action onSelectAll = (property, values, key) => {
    if (this[property].length) {
      this[property] = [];
    } else {
      this[property] = values.map(s => s[key]);
    }
  }
}
